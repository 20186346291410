@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600;700;800;900&display=swap");
:root {
  --White: #ffffff;
  --ExtraLightGrey: #f0f2fa;
  --LightGrey: #d9e4fe;
  --MediumGrey: #747484;
  --DarkGrey: #454550;
  --LightBlue: #5398ff;
  --MediumBlue: #066ff9;
  --DarkBlue: #1963f1;
  --LightDisabled: #666eb8;
  --DarkDisabled: #2e3681;
  --LightBackground: #23296c;
  --DarkBlueBackground: #02044a;
  --LightGreen: #81c083;
  --LightYellow: #fccf8c;
  --LightError: #ff627e;
}

html {
  font-size: 16px;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* comment to check dev errors when needed */
#webpack-dev-server-client-overlay-div,
#webpack-dev-server-client-overlay {
  /* this was done to avoid the Firebase permission error */
  /* TODO: make Firebase Error only appears if user exists and missing permissions only */
  display: none !important;
}

.App {
  min-height: 100vh;
  font-family: "Hind Siliguri";
}

.loading {
  -webkit-animation: rotating 1s linear infinite;
  -moz-animation: rotating 1s linear infinite;
  animation: rotating 1s linear infinite;
}

.MuiSnackbar-root {
  z-index: 999999 !important;
}

.MuiPopover-root {
  z-index: 9999999 !important;
}

@-moz-keyframes rotating {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes rotating {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
